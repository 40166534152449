import {query_IsAuthorized, query_Project} from "~/graphql/projects";
import {query_IsPartOfProject} from "~/graphql/user";


export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig()

    const authenticationCookie = useCookie('auth')
    console.log("Cookie auth");
    console.log(authenticationCookie.value);
    const autorisationCookie = useCookie('autorisation')
    console.log("Cookie autorisation");
    console.log(autorisationCookie.value);

    //console.log(to);
    let projectID = to.path == "/discussion" ? to.query.p : to.params.id;
    let locations = to.query.l;

    

    if (authenticationCookie.value === undefined) {
        if(autorisationCookie.value === undefined)
        {
            getControlMode(projectID).then(function(mode){
                if (mode !== "aucun"){
                    if (to.name === "project-id-calendar") {
                        if (to.path !== '/') return navigateTo('/');
                    }
                    if (to.path !== '/digicode?p='+ projectID + (locations ? '&l='+locations : '')) return navigateTo('/digicode?p='+projectID + (locations ? '&l='+locations : ''));
                }
            })
            
        }else{
            isAutho(projectID).then(function(result){
                if (!result){
                    getControlMode(projectID).then(function(mode){
                        if (mode !== "aucun"){
                            if (to.name === "project-id-calendar") {
                                if (to.path !== '/') return navigateTo('/');
                            }
                            if (to.path !== '/digicode?p='+ projectID  + (locations ? '&l='+locations : '')) return navigateTo('/digicode?p='+projectID  + (locations ? '&l='+locations : ''));
                        }
                    })
                }
            })      
        }
    }else{
        isPartOfProject(projectID).then(function(result){
            if (!result.IsPartOfProject) {
                if(autorisationCookie.value === undefined)
                {
                    getControlMode(projectID).then(function(mode){
                        if (mode !== "aucun"){
                            if (to.name === "project-id-calendar") {
                                if (to.path !== '/') return navigateTo('/');
                            }
                            if (to.path !== '/digicode?p='+ projectID + (locations ? '&l='+locations : '')) return navigateTo('/digicode?p='+projectID + (locations ? '&l='+locations : ''));
                        }
                    })
                    
                }else{
                    isAutho(projectID).then(function(result){
                        if (!result){
                            getControlMode(projectID).then(function(mode){
                                if (mode !== "aucun"){
                                    if (to.name === "project-id-calendar") {
                                        if (to.path !== '/') return navigateTo('/');
                                    }
                                    if (to.path !== '/digicode?p='+ projectID + (locations ? '&l='+locations : '')) return navigateTo('/digicode?p='+projectID + (locations ? '&l='+locations : ''));
                                }
                            })
                        }
                    })      
                }
            }
        })
    }
})

export const isAutho = async (ID) => {
    let {data} = await useNuxtApp().$apollo.clients.default.query({
        query: query_IsAuthorized,
        variables: {
            projectID: ID,
        },
        fetchPolicy: 'no-cache'
    });
    //console.log(data);
    let result = await data.IsAuthorized
    //console.log(result);
    return result;
}

const getControlMode = async (projectID) => {
    let {data} = await useNuxtApp().$apollo.clients.default.query({
        query: query_Project,
        variables: {
            ID: projectID,
        },
        fetchPolicy: 'no-cache'
    });
    //console.log(data);
    let result = await data.Project.control.mode
    //console.log(result);
    return result;
}

export const isPartOfProject = async (projectID) => {
    let {data} = await useNuxtApp().$apollo.clients.user.query({
        query: query_IsPartOfProject,
        variables: {
            projectID: projectID,
        },
        fetchPolicy: 'no-cache'
    });
    //console.log(data);
    return data;
}
